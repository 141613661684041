.qidiruv {
    width: 90%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid red; */
}

.logo {
    width: 90%;
    height: 90px;
}

.qidiruv h3 {
    width: 150px;
    height: 45px;
    padding: 10px;
    border: 1px solid #74B6BF;
    border-radius: 10px;
    font-size: 17px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #212121;
    text-align: center;
}

.qidiruvBtn {
    width: 100%;
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 1px solid blue; */
}

.qidiruvBtn div {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid green; */
}

.qidiruvBtn div button:nth-child(1) {
    width: 70px;
    height: 46px;
    margin: 10px;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: #74B6BF;
    cursor: pointer;
}

.qidiruvBtn div button:nth-child(2) {
    width: 250px;
    height: 46px;
    border: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background: #74B6BF;
   
}

.qidiruvBtn div button a {
    text-decoration: none;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: white;
    cursor: pointer;
}