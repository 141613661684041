.profileSetting div input {
    width: 280px;
    height: 46px;
    margin: 10px;
    padding: 0px 15px;
    border: none;
    border-radius: 15px;
    outline: none;
    background: #82DBD8;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    color: #212121;
}

.profileSetting div label {
    padding: 0px 20px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    color: #314269;
}

.eslatma {
    width: 280px;
    height: 250px;
    background: #82DBD8;
    border-radius: 15px;
}