.alomatBtn {
    width: 90%;
    height: 270px;
}

.alomatBtn div button {
    width: 190px;
    height: 40px;
    margin: 12px;
    background: #4F6EB1;
    border: none;
    border-radius: 10px;
}

.alomatBtn div button a {
    text-decoration: none;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    color: white;
}

.alomat h3 {
    text-align: center;
    width: 210px;
    height: 40px;
    padding: 8px;
    border: 1px solid #ADDEE2;
    border-radius: 10px;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    font-weight: 800;
}